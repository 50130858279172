import { Select } from "antd";
import React, { useEffect, useState } from "react";

const PsSelect = (props) => {
  const [selectedValue, setSelectedValue] = useState(null);

  useEffect(() => {
    setSelectedValue(props.value || null);
  }, [props.value]);

  const onChange = (value) => {
    setSelectedValue(value);
    if (props.onChange) {
      let m = props.dataSource.find(
        (item) => item[props.valueField || "id"] == value
      );
      props.onChange(value, m);
    }
  };

  const onSearch = (value) => {
    if (props.onSearch) props.onSearch(value);
  };

  const labelValue = (item) => {
    let lableField = props.lableField;
    if (lableField && typeof lableField == "function") {
      return props.lableField(item);
    } else {
      return lableField && item[lableField] ? item[lableField] : item.name;
    }
  };
  const options = () => {
    if (props.options) return props.options;
    let rv = [];
    if (props.withNull)
      rv.push({ value: 0, label: props.withNullValue || "None" });
    if (props.dataSource) {
      props.dataSource.map((item, i) => {
        rv.push({
          value:
            props.valueField && item[props.valueField]
              ? item[props.valueField]
              : item.id,
          label: labelValue(item),
        });
      });
    }
    return rv;
  };

  return (
    <>
      {props.inputName && (
        <input type="hidden" name={props.inputName} value={selectedValue} />
      )}
      <Select
        showSearch
        placeholder={props.placeholder || "-Select-"}
        optionFilterProp="children"
        size={props.size || "small"}
        style={{ width: "100%" }}
        value={selectedValue}
        allowClear={true}
        className={props.className}
        filterOption={(input, option) =>
          (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
        }
        onChange={onChange}
        onSearch={onSearch}
        options={options()}
      />
    </>
  );
};

export default PsSelect;
